import http from "@/api/client"

const ENDPOINT_BASE = "/tasks"

const tasks = {
  strict: true,
  namespaced: true,

  state: {
    tasks: [],
    attachments: [],
    activeId: null
  },

  mutations: {
    SET_TASKS(state, newTasks) {
      state.tasks = newTasks
    },
    ADD_TASK(state, task) {
      state.tasks.push(task)
    },
    UPDATE_TASK(state, task) {
      const updated = state.tasks.find((item) => item._id === task._id)
      Object.assign(updated, task)
    },
    SET_ACTIVE(state, id) {
      state.activeId = id
    },
    SET_ATTACHMENTS(state, attachments) {
      state.attachments = attachments
    }
  },

  getters: {},

  actions: {
    fetchAll({ commit }) {
      return http
        .get(ENDPOINT_BASE)
        .then((response) => {
          if (response.data) {
            commit("SET_TASKS", response.data)
          }
          return response
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    },
    fetchById({ commit }, id) {
      commit("SET_ACTIVE", id)
      return http
        .get(`${ENDPOINT_BASE}/${id}`)
        .then((response) => {
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    create({ commit }, data) {
      return http
        .post(ENDPOINT_BASE, data)
        .then((response) => {
          commit("map/ADD_TASK", response.data, { root: true })
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    updateById({ commit }, { data, id }) {
      return http
        .put(`${ENDPOINT_BASE}/${id}`, data)
        .then((response) => {
          commit("assets/UPDATE_TASK", response.data, { root: true })
          commit("map/UPDATE_TASK", response.data, { root: true })
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    deleteById({ commit }, id) {
      return http.delete(`${ENDPOINT_BASE}/${id}`).then((response) => {
        commit("map/REMOVE_TASK", response.data, { root: true })
        return response
      })
    },
    fetchAttachments({ commit, state }, id) {
      const taskId = id || state.activeId
      return http.get(`${ENDPOINT_BASE}/${taskId}/attachments`).then((response) => {
        console.log("attachments", response)
        commit("SET_ATTACHMENTS", response.data)
      })
    }
  }
}

export default tasks

import http from "@/api/client"

const ENDPOINT_BASE = "/emergencies"

const emergencies = {
  strict: true,
  namespaced: true,

  state: {
    emergencies: []
  },

  mutations: {
    SET_EMERGENCIES(state, newItems) {
      state.emergencies = newItems
    },
    ADD_EMERGENCY(state, emergency) {
      state.emergencies.push(emergency)
    }
  },

  getters: {},

  actions: {
    fetchAll({ commit }) {
      return http
        .get(ENDPOINT_BASE)
        .then((response) => {
          if (response.data) {
            commit("SET_EMERGENCIES", response.data)
          }
          return response
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    },
    fetchById(_, id) {
      return http
        .get(`${ENDPOINT_BASE}/${id}`)
        .then((response) => {
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    create({ commit }, data) {
      return http
        .post(ENDPOINT_BASE, data)
        .then((response) => {
          commit("map/ADD_EMERGENCY", response.data[0], { root: true })
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    updateById({ commit }, { data, id }) {
      return http
        .put(`${ENDPOINT_BASE}/${id}`, data)
        .then((response) => {
          commit("map/UPDATE_EMERGENCY", response.data, { root: true })
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    deleteById({ commit }, id) {
      return http.delete(`${ENDPOINT_BASE}/${id}`).then((response) => {
        commit("map/REMOVE_EMERGENCY", response.data, { root: true })
        return response
      })
    }
  }
}

export default emergencies

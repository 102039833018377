const ui = {
  strict: true,
  namespaced: true,

  state: {
    pageLoader: false,
    sidebarShow: "responsive",
    sidebarMinimize: false,
    asideShow: false,
    darkMode: false,
    appModalVisible: false,
    appModalComponent: null,
    innerModalVisible: false,
    innerModalComponent: null,
    modalLoading: false
  },

  mutations: {
    SET_MODAL_LOADING(state, isLoading) {
      state.modalLoading = isLoading
    },
    SET_PAGE_LOADER(state, use) {
      state.pageLoader = use
    },
    SHOW_MENU(state, show) {
      state.menuActive = show
    },
    SET_SIDEBAR_SHOW(state, show) {
      state.sidebarShow = show
    },
    SET_SIDEBAR_MINIZE(state, show) {
      state.sidebarMinimize = show
    },
    SET_DARK_MODE(state, mode) {
      state.darkMode = mode
    },
    SET_ASIDE_SHOW(state, show) {
      state.asideShow = show
    },
    SET_APP_MODAL_COMPONENT(state, componentName) {
      state.appModalComponent = componentName
    },
    SET_APP_MODAL_VISIBILITY(state, show) {
      state.appModalVisible = show
    },
    SET_INNER_MODAL_COMPONENT(state, componentName) {
      state.appModalComponent = componentName
    },
    SET_INNER_MODAL_VISIBILITY(state, show) {
      state.appModalVisible = show
    }
  },

  getters: {},

  actions: {
    setModalLoading({ commit }, isLoading) {
      commit("SET_MODAL_LOADING", isLoading)
    },
    hideInnerModal({ commit }) {
      commit("SET_INNER_MODAL_VISIBILITY", false)
    },
    showInnerModal({ commit }, componentName) {
      commit("SET_INNER_MODAL_VISIBILITY", true)
      commit("SET_INNER_MODAL_COMPONENT", componentName)
    },
    setInnerModalVisibility({ commit }, show) {
      commit("SET_INNER_MODAL_VISIBILITY", show)
    },
    hideAppModal({ commit }) {
      commit("SET_APP_MODAL_VISIBILITY", false)
    },
    showAppModal({ commit }, componentName) {
      commit("SET_APP_MODAL_VISIBILITY", true)
      commit("SET_APP_MODAL_COMPONENT", componentName)
    },
    setAppModalVisibility({ commit }, show) {
      commit("SET_APP_MODAL_VISIBILITY", show)
    },
    toggleSidebarDesktop({ commit, state }) {
      const sidebarOpened = [true, "responsive"].includes(state.sidebarShow)
      const show = sidebarOpened ? false : "responsive"
      commit("SET_SIDEBAR_SHOW", show)
    },
    toggleSidebarMobile({ commit, state }) {
      const sidebarClosed = [false, "responsive"].includes(state.sidebarShow)
      const show = sidebarClosed ? true : "responsive"
      commit("SET_SIDEBAR_SHOW", show)
    },
    setSidebarShow({ commit }, value) {
      commit("SET_SIDEBAR_SHOW", value)
    },
    toggleDarkMode({ commit, state }) {
      commit("SET_DARK_MODE", !state.darkMode)
    },
    setAsideShow({ commit }, value) {
      commit("SET_ASIDE_SHOW", value)
    },
    toggleAsideShow({ commit, state }) {
      commit("SET_ASIDE_SHOW", !state.asideShow)
    },
    toggleSidebarMinize({ commit, state }) {
      commit("SET_SIDEBAR_MINIZE", !state.sidebarMinimize)
    }
  }
}

export default ui

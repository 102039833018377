import Vue from "vue"
import Vuex, { createLogger } from "vuex"

import root from "./modules/root"

/**
 * @function initStore
 *
 * @param {Object} modules Object containing the imported modules for the particular store
 */
export default function initStore(modules) {
  Vue.use(Vuex)

  root.modules = modules

  const store = new Vuex.Store({
    ...root
    // plugins: [createLogger()]
  })

  return store
}

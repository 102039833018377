import {
  cilAccountLogout,
  cilArrowRight,
  cilArrowLeft,
  cilBan,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilCloudUpload,
  cilChartPie,
  cilCheck,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilApplicationsSettings,
  cilStar,
  cilList,
  cilSpeech,
  cilSettings,
  cilLocationPin,
  cibSkype,
  cilHome,
  cilLockLocked,
  cilShieldAlt,
  cilFile,
  cilDollar,
  cilUser,
  cilCommentSquare,
  cilTask,
  cilEnvelopeOpen,
  cilEnvelopeClosed,
  cilListRich,
  cilSpeedometer,
  cilBasket,
  cilUserUnfollow,
  cilUserFollow,
  cilBell,
  cilMoon,
  cilWarning,
  cilGrid,
  cilMagnifyingGlass,
  cilTrash,
  cilSun,
  cilDataTransferUp,
  cilDataTransferDown,
  cilPaperclip,
  cilMap,
  cilOptions,
  cilShareAlt,
  cilCaretBottom,
  cilX,
  cilPlus,
} from "@coreui/icons"
import { logo } from "./logo"

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cilApplicationsSettings,
    cilArrowRight,
    cilArrowLeft,
    cilCloudUpload,
    cilBan,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilStar,
    cilList,
    cilSpeech,
    cilSettings,
    cilLocationPin,
    cibSkype,
    cilHome,
    cilLockLocked,
    cilShieldAlt,
    cilFile,
    cilDollar,
    cilUser,
    cilCommentSquare,
    cilTask,
    cilEnvelopeOpen,
    cilEnvelopeClosed,
    cilListRich,
    cilSpeedometer,
    cilBasket,
    cilUserUnfollow,
    cilUserFollow,
    cilBell,
    cilMoon,
    cilWarning,
    cilGrid,
    cilMagnifyingGlass,
    cilTrash,
    cilSun,
    cilDataTransferUp,
    cilDataTransferDown,
    cilPaperclip,
    cilMap,
    cilOptions,
    cilShareAlt,
    cilAccountLogout,
    cilCaretBottom,
    cilX,
    cilPlus,
  }
)

export const isMobile = () => window.innerWidth <= 768
const isTablet = () => window.innerWidth <= 980

const root = {
  strict: true,

  state: {
    isMobile: isMobile(),
    isTablet: isTablet(),
    loading: false
  },

  mutations: {
    SET_LOADING(state, isLoading) {
      state.loading = isLoading
    }
  },
  actions: {
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading)
    }
  }
}

export default root

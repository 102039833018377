//can use this if we only want to import specific components
import {
  CSidebar,
  CButton,
  CSidebarBrand,
  CIcon,
  CRenderFunction,
  CSidebarMinimizer,
  CSidebarClose,
  CTabs,
  CFooter,
  CDataTable,
  CCardBody,
  CCardHeader,
  CCard,
  CContainer,
  CHeaderNavItem,
  CDropdownItem,
  CDropdownDivider,
  CBadge,
  CDropdownHeader,
  CDropdown,
  CHeaderNavLink,
  CSwitch,
  CHeaderNav,
  CHeaderBrand,
  CToggler,
  CHeader,
  CTab,
  CListGroupItem,
  CWrapper,
  CListGroup,
  CSidebarNavDropdown,
  CSidebarNavTitle,
  CSidebarNav,
  CInput,
  CSelect,
  CTextarea,
  CRow,
  CCol
} from "@coreui/vue-pro"

export const coreUiComponents = [
  CButton,
  CSidebar,
  CSidebarBrand,
  CIcon,
  CRenderFunction,
  CSidebarMinimizer,
  CSidebarClose,
  CTabs,
  CFooter,
  CDataTable,
  CCardBody,
  CCardHeader,
  CCard,
  CContainer,
  CHeaderNavItem,
  CDropdownItem,
  CDropdownDivider,
  CBadge,
  CDropdownHeader,
  CDropdown,
  CHeaderNavLink,
  CSwitch,
  CHeaderNav,
  CHeaderBrand,
  CToggler,
  CHeader,
  CTab,
  CListGroupItem,
  CWrapper,
  CListGroup,
  CSidebarNavDropdown,
  CSidebarNavTitle,
  CSidebarNav,
  CInput,
  CSelect,
  CTextarea,
  CRow,
  CCol
]

import http from "@/api/client"

const venue = {
  strict: true,
  namespaced: true,
  state: {
    vendors: [] //rename this to whatever makes sense
  },

  mutations: {
    SET_VENDORS(state, vendorData) {
      state.vendors = vendorData
    },
    ADD_VENDOR(state, vendor) {
      state.vendors.push(vendor)
    },
    UPDATE_VENDOR(state, vendor) {
      state.vendors.map((v, i) => {
        if (v.id === vendor.id) {
          state.vendors[i] = vendor
        }
      })
    }
  },

  getters: {},

  actions: {
    //just some example stuff, change these to whatever you need
    async getVendorData({ commit }) {
      const data = await http.get("/api/venues")
      commit("SET_VENDORS", data)
    },
    async getVendor({ commit }, id) {
      const data = await http.get(`/api/venues/${id}`)
      commit("UPDATE_VENDOR", data)
    }
  }
}

export default venue

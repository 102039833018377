import MapboxDraw from "@mapbox/mapbox-gl-draw"
import store from "@/store/main"

const ID_KEY = "mapId"

const addDraw = function (map) {
  // map.set(store.state.map.mapFeatures)
  const draw = new MapboxDraw({
    userProperties: true
    //sprite: 'http://localhost:5000/app/map-sprite/icons',
    // styles: customStyles
  })

  console.log(store.state.map.mapFeatures)
  draw.set(store.state.map.mapFeatures)
  map.addControl(draw, "top-left")
  return draw
}

const setMapId = function (id) {
  localStorage.setItem(ID_KEY, id)
}

const getMapId = function () {
  return localStorage.getItem(ID_KEY)
}

export { addDraw, setMapId, getMapId }

<template>
  <div id="app">
    <router-view />
    <notifications group="notifications" />
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css"
export default {
  name: "App",
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.$store.state.user.token) {
        this.$store.dispatch("map/fetchAllData")
      }
    }
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
// @import "~@coreui/coreui/scss/coreui";
</style>

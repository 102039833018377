import http from "@/api/client"
import { DEFAULT_VISIBLE_MARKERS } from "@/data/types.js"
const ENDPOINT_BASE = "/maps"

const map = {
    strict: true,
    namespaced: true,

    state: {
        maps: [],
        mapData: {},
        mapFeatures: {},
        mapLayers: {
            satellite: {
                id: "Satellite",
                enabled: false
            },
            drone: {
                id: "Drone Scan",
                enabled: false
            },
            cad: {
                id: "CAD Map",
                enabled: false
            },
            streets: {
                id: "<no layer>",
                enabled: false
            }
        },
        assets: [],
        tasks: [],
        incidents: [],
        emergencies: [],
        currentMapId: localStorage.getItem("mapId"),
        showTasks: false,
        showIncidents: false,
        showSecurity: false,
        tools: {
            line: false,
            polygon: false
        },
        taskTemp: {},
        incidentTemp: {},
        assetTemp: {},
        activeFeatures: [
            "ATM",
            "Bar",
            "Camera",
            "Note",
            "Disability",
            "Exit",
            "Food",
            "Parking",
            "Restroom",
            "Security",
            "Sponsor",
            "Stage",
            "Tickets",
            "Vendor",
            "Area",
            "Tent",
            "Container",
            "Entrance",
            "Mobile Office",
            "Other",
            "tasks",
            "incidents"
        ]
    },

    mutations: {
        TOGGLE_ACTIVE_FEATURE(state, { name, value }) {
            const activeFeature = state.activeFeatures.includes(name)
            if (!value && activeFeature) {
                state.activeFeatures.splice(state.activeFeatures.indexOf(name), 1)
            } else if (value && !activeFeature) {
                state.activeFeatures.push(name)
            }
        },
        SET_TOOL_STATE(state, { name, value }) {
            state.tools[name] = value
        },
        SET_MAP_LAYER(state, { layerName, enabled }) {
            state.mapLayers[layerName].enabled = enabled
        },
        SET_LAYER_VISIBILITY(state, { layer, visibility }) {
            switch (layer) {
                case "tasks":
                    state.showTasks = visibility
                    break
                case "incidents":
                    state.showIncidents = visibility
                    break
                case "security":
                    state.showSecurity = visibility
                    break
            }
        },
        SET_MAPS(state, maps) {
            state.maps = maps
        },
        SET_ASSETS(state, assets) {
            state.assets = assets
        },
        SET_TASKS(state, tasks) {
            state.tasks = tasks
        },
        SET_INCIDENTS(state, incidents) {
            state.incidents = incidents
        },
        SET_EMERGENCIES(state, emergencies) {
            state.emergencies = emergencies
        },
        SET_MAP_DATA(state, mapData) {
            state.mapData = mapData
        },
        SET_MAP_FEATURES(state, mapFeatures) {
            state.mapFeatures = mapFeatures
        },
        SET_CURRENT_MAP_ID(state, id) {
            localStorage.setItem("mapId", id)
            state.currentMapId = id
        },
        ADD_ASSET(state, asset) {
            state.mapFeatures.features.push(asset)
            state.assets.push(asset)
        },
        REMOVE_ASSET(state, asset) {
            state.mapFeatures.features = state.mapFeatures.features.filter((item) => item._id !== asset._id)
            state.assets = state.assets.filter((item) => item._id !== asset._id)
        },
        UPDATE_ASSET(state, asset) {
            let updated = state.mapFeatures.features.find((item) => item._id === asset._id)
            if (updated) {
                Object.assign(updated, asset)
            }

            updated = state.assets.find((item) => item._id === asset._id)
            if (updated) {
                Object.assign(updated, asset)
            }
        },
        SET_ASSET_TEMP(state, assetTemp) {
            state.assetTemp = assetTemp;
        },
        ADD_INCIDENT(state, incident) {
            state.incidents.push(incident)
        },
        REMOVE_INCIDENT(state, incident) {
            state.incidents = state.incidents.filter((item) => item._id !== incident._id)
        },
        UPDATE_INCIDENT(state, incident) {
            const updated = state.incidents.find((item) => item._id === incident._id)
            if (updated) Object.assign(updated, incident)
        },
        SET_INCIDENT_TEMP(state, incidentTemp) {
            state.incidentTemp = incidentTemp;
        },
        ADD_TASK(state, task) {
            state.tasks.push(task)
        },
        REMOVE_TASK(state, task) {
            state.tasks = state.tasks.filter((item) => item._id !== task._id)
        },
        UPDATE_TASK(state, task) {
            const updated = state.tasks.find((item) => item._id === task._id)
            if (updated) {
                Object.assign(updated, task)
            }
        },
        SET_TASK_TEMP(state, taskTemp) {
            state.taskTemp = taskTemp;
        },
        ADD_EMERGENCY(state, emergency) {
            state.emergencies.push(emergency)
        },
        REMOVE_EMERGENCY(state, emergency) {
            state.emergencies = state.emergencies.filter((item) => item._id !== emergency._id)
        },
        UPDATE_EMERGENCY(state, emergency) {
            const updated = state.emergencies.find((item) => item._id === emergency._id)
            if (updated) {
                Object.assign(updated, emergency)
            }
        },
    },

    getters: {
        upComingAndPreviousMaps() {
            return {}
        },
        mapId(state) {
            return localStorage.getItem("mapId") || state.currentMapId
        },
        mapboxData(state) {
            let data = {
                type: "FeatureCollection"
            }
            let features = []
            if (state.mapFeatures && state.mapFeatures.features) {
                features = state.mapFeatures.features.map((feature) => {
                    if (feature.properties && feature.properties.skyfall) {
                        return {
                            ...feature,
                            properties: {
                                routeName: "asset-detail",
                                customType: "asset",
                                ...feature.properties,
                                ...feature.properties.skyfall,
                                description: feature.properties.skyfall.content
                            }
                        }
                    } else return feature
                })
            }
            data.features = features
            return data
        },
        mapIncidentFeatures(state) {
            let data = {
                type: "FeatureCollection"
            }
            let features = []
            if (state.incidents.length) {
                features = state.incidents
                    .filter((incident) => incident.geoLocation)
                    .map((incident) => {
                        return {
                            ...incident,
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [incident.geoLocation.lng, incident.geoLocation.lat]
                            },
                            properties: {
                                routeName: "incident-detail",
                                customType: "incidents",
                                name: incident.title,
                                id: incident._id,
                                description: incident.notes,
                                type: "Incident"
                            }
                        }
                    })
                data.features = features
            }
            return data
        },
        mapTaskFeatures(state) {
            let data = {
                type: "FeatureCollection"
            }
            let features = []
            if (state.tasks.length) {
                features = state.tasks
                    .filter((task) => task.geoLocation)
                    .map((task) => {
                        return {
                            ...task,
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [task.geoLocation.lng, task.geoLocation.lat]
                            },
                            properties: {
                                routeName: "task-detail",
                                customType: "tasks",
                                name: task.title,
                                id: task._id,
                                description: task.notes,
                                type: "Task"
                            }
                        }
                    })
                data.features = features
            }
            return data
        },
        combinedMapFeatures(state, getters) {
            // This function has extensive console logging because it's difficult to break in it

            let features = []

            let ctFeatures = 0
            let ctTasks = 0
            let ctIncidents = 0
            let ctAssets = 0

            if (getters.mapTaskFeatures.features) {
                features.push(...getters.mapTaskFeatures.features)
                ctTasks = getters.mapTaskFeatures.features.length
                ctFeatures += ctTasks
            }
            if (getters.mapboxData.features) {
                features.push(...getters.mapboxData.features)
                ctAssets = getters.mapboxData.features.length
                ctFeatures += ctAssets
            }
            if (getters.mapIncidentFeatures.features) {
                features.push(...getters.mapIncidentFeatures.features)
                ctIncidents = getters.mapIncidentFeatures.features.length
                ctFeatures += ctIncidents
            }

            // console.log(`combinedMapFeatures: total: ${ctFeatures} assets: ${ctAssets} tasks: ${ctTasks} incidents: ${ctIncidents}`)
            features = features.filter((feature) => {
                if (feature.properties.customType || feature.properties.type) {

                    let fInclude = state.activeFeatures.includes(feature.properties.customType);

                    if (!fInclude) {
                        let matchList = [...DEFAULT_VISIBLE_MARKERS, ...state.activeFeatures];
                        fInclude = matchList.includes(feature.properties.skyfall.type);
                    }
                    return fInclude;
                } else {
                    return false
                }
            })
            return {
                type: "FeatureCollection",
                features: features
            }
        },
        assetData: (state) => {
            let propertiesArray = []
            if (state.assets.length) {
                state.assets.forEach((asset) => {
                    let newProperties = []
                    if (asset.properties && asset.properties.length) {
                        propertiesArray.push({ ...asset.properties[0], id: asset._id })
                    } else if (!Array.isArray(asset.properties)) {
                        propertiesArray.push({ ...asset.properties, id: asset._id })
                    }
                })
            }
            return propertiesArray
        },
        skyFallAssetData: (state) => {
            return state.assets
                .filter((asset) => {
                    const { skyfall } = asset.properties
                    return skyfall && (skyfall.name || skyfall.dept || skyfall.type)
                })
                .map((asset) => {
                    return {
                        ...asset.properties.skyfall,
                        id: asset._id
                    }
                })
        },
        mapFeaturesAndId: (state) => {
            return (
                state.mapFeatures.features &&
                state.mapFeatures.features.map((feature) => {
                    if (feature._id && feature.properties) {
                        let newEntry = {}
                        if (feature.properties.skyfall) {
                            newEntry = {
                                ...feature.properties.skyfall,
                                id: feature._id
                            }
                        } else {
                            newEntry = {
                                ...feature.properties,
                                id: feature._id
                            }
                        }
                        return newEntry
                    }
                })
            )
        },
        assetTemp: (state) => {
            return (state.assetTemp);
        },
        incidentTemp: (state) => {
            return (state.incidentTemp);
        },
        taskTemp: (state) => {
            return (state.taskTemp);
        },
    },

    actions: {
        loadMaps({ commit }) {
            return http
                .get(`${ENDPOINT_BASE}/loadUserMaps`)
                .then((response) => {
                    if (response.data) {
                        commit("SET_MAPS", response.data)
                    }
                    return response
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        loadMapData({ commit }, mapId) {
            commit("SET_CURRENT_MAP_ID", mapId)
            return http
                .get(`${ENDPOINT_BASE}/${mapId}`)
                .then((response) => {
                    if (response.data) {
                        commit("SET_MAP_DATA", response.data)
                    }
                    return response
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        loadMapFeatures({ commit }, mapId) {
            return http
                .get(`${ENDPOINT_BASE}/${mapId}/assets?type=features`)
                .then((response) => {
                    if (response.data) {
                        commit("SET_MAP_FEATURES", response.data)
                    }
                    return response
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        setMapId({ commit }, mapId) {
            commit("SET_CURRENT_MAP_ID", mapId)
        },
        fetchAssets({ commit, state }) {
            if (state.currentMapId) {
                return http
                    .get(`${ENDPOINT_BASE}/${state.currentMapId}/assets`)
                    .then((response) => {
                        commit("SET_ASSETS", response.data)
                        return response
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        fetchTasks({ commit, state }) {
            // console.log(state)
            if (state.currentMapId) {
                return http
                    .get(`${ENDPOINT_BASE}/${state.currentMapId}/tasks`)
                    .then((response) => {
                        commit("SET_TASKS", response.data)
                        return response
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        fetchIncidents({ commit, state }) {
            return http
                .get(`${ENDPOINT_BASE}/${state.currentMapId}/incidents`)
                .then((response) => {
                    commit("SET_INCIDENTS", response.data)
                    return response
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        fetchEmergencies({ commit, state }) {
            return http
                .get(`${ENDPOINT_BASE}/${state.currentMapId}/emergencies`)
                .then((response) => {
                    commit("SET_EMERGENCIES", response.data)
                    return response
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        fetchAllData({ dispatch, commit, state }) {
            if (state.currentMapId) {
                const fetches = [dispatch("fetchAssets"), dispatch("fetchTasks"), dispatch("fetchIncidents"), dispatch("fetchEmergencies")]
                return Promise.all(fetches).then(([assets, tasks, incidents, emergencies]) => {
                    commit("SET_ASSETS", assets.data)
                    commit("SET_TASKS", tasks.data)
                    commit("SET_INCIDENTS", incidents.data)
                    commit("SET_EMERGENCIES", emergencies.data)
                })
            }
        },
        setActiveTool({ commit, state }, data) {
            for (let tool in state.tools) {
                if (data.name === tool) {
                    commit("SET_TOOL_STATE", { name: tool, value: true })
                    return
                } else {
                    commit("SET_TOOL_STATE", { name: tool, value: false })
                }
            }
        },
        setAssetTemp({ commit }, assetTemp) {
            commit("SET_ASSET_TEMP", assetTemp)
        },
        setIncidentTemp({ commit }, incidentTemp) {
            commit("SET_INCIDENT_TEMP", incidentTemp)
        },
        setTaskTemp({ commit }, taskTemp) {
            commit("SET_TASK_TEMP", taskTemp)
        },
    }
}

export default map

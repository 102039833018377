import axios from "axios"
import * as TokenTools from "@/utils/TokenTools.js"
let base = "/"
if (window.location.href.includes("localhost")) {
  base = "http://localhost:5000/"
}
const ServerRequest = () => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_API_BASE || base,
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  }

  // Create instance
  let instance = axios.create(defaultOptions)

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = TokenTools.getAuthToken()
    config.headers.Authorization = token ? `Bearer ${token}` : ""
    return config
  })

  return instance
}

export default ServerRequest()

import http from "@/api/client"

const ENDPOINT_BASE = "/incidents"

const incidents = {
    strict: true,
    namespaced: true,

    state: {
        incidents: [],
        attachments: [],
        activeId: null
    },

    mutations: {
        SET_INCIDENTS(state, newIncidents) {
            state.incidents = newIncidents
        },
        ADD_INCIDENT(state, incident) {
            state.incidents.push(incident)
        },
        UPDATE_INCIDENT(state, incident) {
            const updated = state.incidents.find((item) => item._id === incident._id)
            Object.assign(updated, incident)
        },
        SET_ACTIVE(state, id) {
            state.activeId = id
        },
        SET_ATTACHMENTS(state, attachments) {
            state.attachments = attachments
        }
    },

    getters: {},

    actions: {
        fetchAll({ commit }) {
            return http
                .get(ENDPOINT_BASE)
                .then((response) => {
                    if (response.data) {
                        commit("SET_INCIDENTS", response.data)
                    }
                    return response
                })
                .catch((err) => {
                    console.log(err)
                    return err
                })
        },
        fetchById({ commit }, id) {
            commit("SET_ACTIVE", id)
            return http
                .get(`${ENDPOINT_BASE}/${id}`)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        create({ commit }, data) {
            return http
                .post(ENDPOINT_BASE, data)
                .then((response) => {
                    commit("map/ADD_INCIDENT", response.data, { root: true })
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        deleteById({ commit }, id) {
            return http
                .delete(`${ENDPOINT_BASE}/${id}`)
                .then((response) => {
                    commit("map/REMOVE_INCIDENT", response.data, { root: true })
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        updateById({ commit }, { data, id }) {
            return http
                .put(`${ENDPOINT_BASE}/${id}`, data)
                .then((response) => {
                    commit("map/UPDATE_INCIDENT", response.data, { root: true })
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        fetchAttachments({ commit, state }, id) {
            const incidentId = id || state.activeId
            return http.get(`${ENDPOINT_BASE}/${incidentId}/attachments`).then((response) => {
                console.log("attachments", response)
                commit("SET_ATTACHMENTS", response.data)
            })
        }
    }
}

export default incidents

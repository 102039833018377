import http from "@/api/client"

const ENDPOINT_BASE = "/exceptions"

const exceptions = {
    strict: true,
    namespaced: true,

    getters: {},

    actions: {
        create({ commit }, data) {
            return http
                .post(ENDPOINT_BASE, data)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    throw err
                })
        }
    }
}

export default exceptions

import http from "@/api/client"

const ENDPOINT_BASE = "/companies"

const companies = {
  strict: true,
  namespaced: true,

  state: {
    companies: []
  },

  mutations: {
    SET_ACTIVE(state, id) {
      state.activeId = id
    }
  },

  getters: {},

  actions: {
    fetchLogos({ commit }, id) {
      return http
        .get(`${ENDPOINT_BASE}/${id}/logos`)
        .then(({ data }) => {
          commit("SET_TASKS", data)
          return data
        })
        .catch((err) => {
          throw err
        })
    },
    fetchById({ commit }, id) {
      commit("SET_ACTIVE", id)
      return http
        .get(`${ENDPOINT_BASE}/${id}`)
        .then((response) => {
          return response
        })
        .catch((err) => {
          throw err
        })
    }
  }
}

export default companies

import "core-js/stable"
import Vue from "vue"
import App from "./App"
import router from "./router"
import CoreuiVuePro from "../node_modules/@coreui/vue-pro/src/index.js"
// import CoreuiVuePro from './coreui'
import JsonCSV from "vue-json-csv"
import VModal from "vue-js-modal"
import Vuelidate from "vuelidate"
import vSelect from "vue-select"
import clampy from "@clampy-js/vue-clampy"
import PortalVue from "portal-vue"
import Notifications from "vue-notification"
import VueLoading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { coreUiComponents } from "./coreui/components"

import ServerRequest from "@/api/client"

import { iconsSet as icons } from "./assets/icons/icons.js"
import store from "./store/main"

// import {CSidebar} from '@coreui/vue-pro'
// coreUiComponents.forEach((key, value) => {
//   console.log(key, value)
//   Vue.component(key.name, key)
// })
// Vue.component('CSidebar', CSidebar)

Vue.config.performance = true

function saveException(err, vm, info) {
    console.log(`Save Exception.  User: ${store.state.user.user.name}, err: ${err}.`)

    let component = "";

    if (vm._name)
        component = vm._name.replaceAll('<', '').replaceAll('>', '');

    let exceptionEntry = {
        version: process.env.VUE_APP_VERSION,
        hostName: window.location.hostname,
        href: window.location.href,
        component: component,
        err: err.message
    }

    store
        .dispatch("exceptions/create", exceptionEntry)
        .then(() => {
            console.log("Exception saved")
        })
        .catch((err) => {
            console.error(err)
        })
}

Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    // TODO: Perform any custom logic or log to server

    let vmName = "(none)";

    if (vm) {
        vmName = vm._name;
    }

    console.log(`Vue error handler. Component: ${vmName} Error: ${err}`)
    saveException(err, vm, info)
}

window.onerror = function (message, source, lineno, colno, error) {

    console.log('Javascript error handler')
};

Vue.use(CoreuiVuePro)
Vue.use(VModal)
Vue.use(Vuelidate)
Vue.use(clampy)
Vue.use(PortalVue)
Vue.use(Notifications)
Vue.use(VueLoading)

Vue.component("downloadCsv", JsonCSV)
Vue.component("v-select", vSelect)

Vue.prototype.$log = console.log.bind(console)

Vue.prototype.$http = ServerRequest

Bugsnag.start({
    apiKey: 'd0e872a2d69a7bc56606364c6a3e0493',
    plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

new Vue({
    router,
    store,
    icons,
    render: (h) => h(App)
}).$mount("#app")

import Vue from "vue"
import Router from "vue-router"
import platform from "platform"
import { getAuthToken, removeUserTokens, tokenIsExpired } from "../utils/TokenTools"
import * as RouteGuard from "./route-guards"
import store from "@/store/main"
// Containers
const TheContainer = () => import("@/containers/TheContainer")
// Views - Pages
const Page404 = () => import("@/views/error/Page404")
const PageRegister = () => import("@/views/auth/PageRegister")
//user
const UserDetails = () => import("@/views/user/UserDetails")
//map
const MapSelection = () => import("@/views/map/MapSelection")
const MainMap = () => import("@/views/map/MainMap")
const BaseModal = () => import("@/components/modals/BaseModal")
Vue.use(Router)
import { mapGetters } from "vuex";

const AssetRoutes = [
    {
        name: "assets",
        path: "/assets",
        component: null
    },
    {
        name: "new-asset",
        path: "/assets/new",
        component: null
    },
    {
        name: "asset-detail",
        path: "/assets/:id/",
        component: null
    },
]

const TaskRoutes = [
    {
        name: "tasks",
        path: "/tasks",
        component: null
    },
    {
        name: "new-task",
        path: "/tasks/new",
        component: null,
        beforeEnter: (to, from, next) => {
            console.log(`new-task::beforeEnter.  To: ${to.name}, from: ${from.name}.`)
            next()
        },
    },
    {
        name: "task-detail",
        path: "/tasks/:id",
        component: null,
        beforeEnter: (to, from, next) => {
            console.log(`task-detail::beforeEnter.  To: ${to.name}, from: ${from.name}.`)
            next()
        },
    }
]
const IncidentRoutes = [
    {
        name: "incidents",
        path: "/incidents",
        component: null
    },
    {
        name: "new-incident",
        path: "/incidents/new",
        component: null
    },
    {
        name: "incident-detail",
        path: "/incidents/:id/",
        component: null
    }
]
const EmergencyRoutes = [
    {
        name: "emergencies",
        path: "/emergencies",
        component: null
    },
    {
        name: "new-emergency",
        path: "/emergencies/new",
        component: null
    },
    {
        name: "emergency-detail",
        path: "/emergencies/:id/",
        component: null
    }
]

const UserRoutes = [
    {
        name: "map-user-details",
        path: "/user",
        component: BaseModal,
        meta: {
            label: "User Details"
        },
        props: {
            component: UserDetails,
            title: "User Settings",
            colorclass: "normal"
        }
    },
    {
        name: "selection-user-details",
        path: "/maps/user",
        component: BaseModal,
        meta: {
            label: "User Details"
        },
        props: {
            component: UserDetails,
            title: "User Settings",
            colorclass: "normal"
        }
    }
]

const CompanyRoutes = [
    {
        name: "companies",
        path: "/companies",
        component: null
    },
    {
        name: "company-detail",
        path: "/companies/:id/",
        component: null
    }
]

const LogRoutes = [
    {
        name: "new-log",
        path: "/logs/new",
        component: null
    }
]

const ExceptionRoutes = [
    {
        name: "new-exception",
        path: "/exceptions/new",
        component: null
    }
]

function configRoutes() {
    return [
        {
            path: "/",
            component: TheContainer,
            meta: {
                requiresAuth: true,
                redirectIfNoMapId: true,
                label: " "
            },
            children: [
                {
                    path: "",
                    name: "map",
                    meta: {
                        label: "Map"
                    },
                    component: MainMap,
                    children: [...AssetRoutes, ...TaskRoutes, ...IncidentRoutes, ...EmergencyRoutes, UserRoutes[0], ...CompanyRoutes, ...LogRoutes, ...ExceptionRoutes]
                }
            ]
        },
        {
            path: "/login",
            name: "login",
            component: PageRegister,
            meta: {
                redirectIfAuthenticated: true
            }
        },
        {
            path: "/register",
            name: "register",
            component: PageRegister,
            meta: {
                redirectIfAuthenticated: true
            }
        },
        {
            path: "/maps",
            name: "maps",
            component: MapSelection,
            meta: {
                requiresAuth: true
            },
            children: [UserRoutes[1]]
        },

        {
            path: "/*",
            component: Page404
        }
    ]
}

let routeList = configRoutes();

let router = new Router({
    mode: "hash",
    // mode: 'history',
    // hash: false,
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: routeList,
})

function saveLogEntry(store, to, from) {
    let logEntry = {
        fromName: from.name,
        fromPath: from.fullPath,
        toName: to.name,
        toPath: to.fullPath,
        version: process.env.VUE_APP_VERSION,
        hostName: window.location.hostname,
        href: window.location.href,
        browser: platform.name,
        browserVersion: platform.version,
        os: platform.os.family,
        osVersion: platform.os.version,
    }

    let p = platform;

    store
        .dispatch("logs/create", logEntry)
        .then(() => {
            // console.log("Log entry created")
        })
        .catch((err) => {
            // Do not log an error if authentication fails on /login.
            // Correctly, there is no authenticated user at this point
            if (logEntry.toName != "login") console.error(err)
        })
}

router.beforeEach((to, from, next) => {
    console.log(`beforeEach.  To: ${to.name}, from: ${from.name}.`);

    saveLogEntry(store, to, from)

    const { currentMapId } = store.state.map
    const authenticated = getAuthToken() && !tokenIsExpired()

    if (!authenticated && to.name !== "login") {
        next({ name: "login" })
        return
    }

    if (to.meta.requiresAuth && !authenticated) {
        next({ name: "login" })
        return
    }

    if (tokenIsExpired()) {
        removeUserTokens()
        next({ name: "login" })
        return
    }

    if (to.meta.redirectIfNoMapId && to.name !== "maps") {
        next({ name: "maps" })
        return
    }

    if (authenticated && !currentMapId && to.name !== "maps") {
        next({ name: "maps" })
        return
    }

    if (authenticated && currentMapId && (to.query.mapId || from.query.mapId)) {
        store.dispatch("map/setMapId", to.query.mapId || from.query.mapId)
    }

    if (authenticated && currentMapId && !to.query.mapId) {
        next({ ...to, query: { ...to.query, mapId: store.state.map.currentMapId } })
    }
    next()
})

function pluralize(entityType) {

    switch (entityType) {

        case 'emergency':
            return ('emergencies');
    }

    return (entityType + 's');
}

function deleteTemporary(store, routeName) {

    // Return is this is not a "new-*" route

    if (routeName === null)
        return;

    let words = routeName.split('-');

    if (words.length < 2)
        return;

    if (words[0] !== "new")
        return;

    let tempEntityType = `${words[1]}Temp`;

    let entityTemp = store.getters[`map/${tempEntityType}`];

    if (entityTemp.saved)
        return;

    let id = entityTemp.id;

    let entityType = pluralize(words[1]);

    store.dispatch(`${entityType}/deleteById`, id);
}

router.afterEach((to, from) => {
    deleteTemporary(store, from.name);

    console.log(`afterEach.  To: ${to.name}, from: ${from.name}.`)
})

export default router

import testAssets from "@/data/testAssets.json"
import http from "@/api/client"

const ENDPOINT_BASE = "/assets"

const assets = {
    strict: true,
    namespaced: true,

    state: {
        assets: [],
        tasks: []
    },

    mutations: {
        SET_ASSETS(state, newAssets) {
            state.assets = newAssets
        },
        SET_TASKS(state, tasks) {
            state.tasks = tasks
        },
        UPDATE_TASK(state, task) {
            const updated = state.tasks.find((item) => item._id === task._id)
            if (updated) {
                Object.assign(updated, task)
            }
        }
    },

    getters: {
        getAssetByID: (state, getters) => (id) => {
            const { propertiesArray } = getters
            let asset = propertiesArray.filter((asset) => asset.id === id)
            return asset
        },
        // mapAssets: function(state, getters, rootState) {
        //   console.log(rootState);
        //   return {}
        // },
        propertiesArray: (state) => {
            let propertiesArray = []
            state.assets.forEach((asset) => {
                propertiesArray.push(Object.assign({}, ...asset.properties, { id: asset._id }))
            })
            return propertiesArray
        },
        completedPropertiesArray:
            (state, getters) =>
                (maxNumber = state.assets.length) => {
                    return getters.propertiesArray.filter((item) => item.completed === false).slice(0, maxNumber)
                }
    },

    actions: {
        updateAsset({ commit, state }, payload) {
            const newAssets = []
            state.assets.forEach((asset) => {
                if (asset.id === payload.id) {
                    console.log("match", payload)
                    newAssets.push(payload)
                } else {
                    newAssets.push(asset)
                }
            })
            commit("SET_ASSETS", newAssets)
        },
        fetchAll({ commit }) {
            http
                .get(ENDPOINT_BASE)
                .then((response) => {
                    if (response.data) {
                        commit("SET_ASSETS", response.data)
                    }
                    return response
                })
                .catch((err) => {
                    return err
                })
        },
        fetchTasks({ commit }, id) {
            return http
                .get(`${ENDPOINT_BASE}/${id}/tasks`)
                .then(({ data }) => {
                    commit("SET_TASKS", data)
                    return data
                })
                .catch((err) => {
                    throw err
                })
        },
        fetchById(_, id) {
            return http
                .get(`${ENDPOINT_BASE}/${id}`)
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        create({ commit }, data) {
            return http
                .post(ENDPOINT_BASE, data)
                .then((response) => {
                    commit("map/ADD_ASSET", response.data, { root: true })
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        deleteById({ commit }, id) {
            return http
                .delete(`${ENDPOINT_BASE}/${id}`)
                .then((response) => {
                    commit("map/REMOVE_ASSET", response.data, { root: true })
                    return response
                })
                .catch((err) => {
                    throw err
                })
        },
        updateById({ commit }, { data, id }) {
            return http
                .put(`${ENDPOINT_BASE}/${id}`, data)
                .then((response) => {
                    commit("map/UPDATE_ASSET", response.data, { root: true })
                    return response
                })
                .catch((err) => {
                    throw err
                })
        }
    }
}

export default assets

// Date JS tools
// import * as DateTools from '@/utils/DateTools.js'
import decode from "jwt-decode"
const TOKEN = "accessToken"

function removeUserTokens() {
  localStorage.removeItem(TOKEN)
}

function getAuthToken() {
  return localStorage.getItem(TOKEN)
}

function getDecodedUserData() {
  const authToken = getAuthToken()
  return authToken && decode(authToken)
}

function getFullUserName() {
  const data = getDecodedUserData()
  let username = ""
  if (data && data.username) {
    username = data.username
  }
  return username
}

function getEmail() {
  const data = getDecodedUserData()
  let email = ""
  if (data && data.email) {
    email = data.email
  }
  return email
}

function tokenIsExpired() {
  const token = getAuthToken()
  if (token && decode(token).exp < Date.now() / 1000) {
    removeUserTokens()
    return true
  }
  return false
}

function setToken(data) {
  localStorage.setItem("accessToken", data.accessToken)
}

export { getFullUserName, getEmail, tokenIsExpired, getDecodedUserData, removeUserTokens, getAuthToken, setToken }

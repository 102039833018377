import { getDecodedUserData, getAuthToken, tokenIsExpired, removeUserTokens } from "@/utils/TokenTools.js"

import map from "@/store/modules/map"

import { getMapId, setMapId } from "@/utils/MapTools.js"

const redirectIfUnauthenticated = function (to, from, next) {
  if (getAuthToken() == null) {
    next({
      name: "login"
    })
  } else {
    next()
  }
}

const verifyToken = function (to, from, next) {
  if (tokenIsExpired() === true) {
    removeUserTokens()
    next({
      name: "login"
    })
  } else {
    next()
  }
}

const redirectIfAuthenticated = function (to, from, next) {
  if (getAuthToken()) {
    next({
      name: "map"
    })
  } else {
    next()
  }
}

const redirectIfNoMapId = function (to, from, next) {
  const mapId = getMapId()
  if (!mapId) {
    next({
      name: "maps"
    })
  } else {
    next()
  }
}

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

const addMapIdQueryParam = function (to, from, next) {
  // if (!hasQueryParams(to) && hasQueryParams(from)) {
  //   next({ name: to.name, query: from.query, params: to.params })
  // } else if (map.state.currentMapId) {
  //   next()
  // }
  if (map.state.currentMapId) {
    next({ ...to, query: { ...to.query, mapId: map.state.currentMapId } })
  }
}

export { redirectIfUnauthenticated, redirectIfAuthenticated, verifyToken, redirectIfNoMapId, addMapIdQueryParam }

import initStore from "./index"
import ui from "./modules/ui.js"
import assets from "./modules/assets.js"
import tasks from "./modules/tasks.js"
import user from "./modules/user.js"
import map from "./modules/map.js"
import incidents from "./modules/incidents.js"
import emergencies from "./modules/emergencies.js"
import companies from "./modules/companies.js"
import logs from "./modules/logs.js"
import exceptions from "./modules/exceptions.js"
import venue from "./modules/venue.js"

export default initStore({
    ui,
    user,
    assets,
    tasks,
    map,
    incidents,
    emergencies,
    companies,
    venue,
    logs,
    exceptions
})

import router from "@/router"
import * as TokenTools from "@/utils/TokenTools.js"
import http from "@/api/client"

const ENDPOINT_BASE = "/users"

const user = {
  strict: true,
  namespaced: true,

  state: {
    user: {
      name: TokenTools.getFullUserName()
    },
    isAdmin: true, //TODO: have this set on login
    token: TokenTools.getAuthToken()
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_IS_ADMIN(state, isAdmin) {
      state.isAdmin = isAdmin
    },
    SET_USER(state, { email, firstName, lastName }) {
      state.user.email = email
      state.user.firstName = firstName
      state.user.lastName = lastName
    },
    RESET_USER(state) {
      state.user = {
        email: "",
        firstName: "",
        lastName: ""
      }
    }
  },

  getters: {
    isAuthenticated: (state) => {
      return state.token !== null && state.token !== ""
    },
  },

  actions: {
    logout({ dispatch }) {
      dispatch("setToken", null)
      //localStorage.removeItem('accessToken')
      //localStorage.removeItem('refreshToken')
      TokenTools.removeUserTokens()
      router.push({ name: "login" })
      //redirect?
    },

    login({ commit }, data) {
      return http.post("/auth/login", data, {
        params: {
          jwt: true
        }
      })
    },

    refreshLogin() {
      return http.get("/auth/refresh-token")
    },

    setToken({ commit }, token) {
      if (token === null) {
        commit("SET_TOKEN", null)
        localStorage.removeItem("token")
        commit("RESET_USER")
      } else {
        commit("SET_TOKEN", token)
        localStorage.setItem("token", token)
      }
    },
    fetchAll({ commit }) {
      return http
        .get(ENDPOINT_BASE)
        .then((response) => {
          return response
        })
        .catch((err) => {
          return err
        })
    },
  }
}

export default user
